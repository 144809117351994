import { Injectable } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(
    private message: NzMessageService,
    private notification: NzNotificationService
  ) { }

  createNotification(type: string, title: string, text: string, duration?: number): void {
    if(!duration) {
      duration = 5000;
    }
    this.notification.create(
      type,
      title,
      text,
      { nzDuration: duration },
    );
  }

  createMessage(type: string, text: string, duration?: number): void {
    if(!duration) {
      duration = 5000;
    }
    this.message.create(type, text, { nzDuration: duration });
  }

}
