import { AuthModel } from './auth.model';
import { AddressModel } from './address.model';
import { SocialNetworksModel } from './social-networks.model';

export class UserModel extends AuthModel {
  password: string;
  first_name: string;
  last_name: string;
  email: string;
  pic: string;
  role_id: number | unknown;
  user_id: string;
  broker: boolean;
  pesel: number | null;
  contractor: false;
  trusted_broker: false;
  job_offerer: false;
  logged_at: Date;
  expires_at: Date;
  form_of_cooperation: number;
  company_name: string | null;
  nip: string | null;
  bank_account: string | null;
  address?: {
    city: string;
    postal_code: string;
    street: string;
    street_number: string;
    building_number: string;
    apartment_number: string;
  };
  phone_number: string;
  consents?: Array<{
    consent_id: number;
    consent_accepted: boolean;
  }>;
  form_of_cooperation_name: string;
  created_at?: Date;

  setUser(_user: unknown) {
    const user = _user as UserModel;
    this.user_id = user.user_id;
    this.first_name = user.first_name;
    this.last_name = user.last_name;
    this.role_id = user.role_id;
    this.password = user.password || '';
    this.email = user.email || '';
    this.pic = user.pic || './assets/media/avatars/blank.png'; // Ustawienie domyślnego obrazka, jeśli nie istnieje
    this.broker = user.broker || false;
    this.contractor = user.contractor || false;
    this.trusted_broker = user.trusted_broker || false;
    this.job_offerer = user.job_offerer || false;
    this.logged_at = new Date(user.logged_at);
    this.expires_at = new Date(user.expires_at);
    this.form_of_cooperation = user.form_of_cooperation;
    this.company_name = user.company_name;
    this.nip = user.nip;
    this.bank_account = user.bank_account;
    this.address = user.address;
    this.phone_number = user.phone_number;
    this.consents = user.consents;
    this.form_of_cooperation_name = user.form_of_cooperation_name;
    this.pesel = user.pesel;
  }
}