import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { UserModel } from '../../models/user.model';
import { environment } from '../../../../../environments/environment';
import { AuthModel } from '../../models/auth.model';

const API_USERS_URL = `${environment.apiUrl}${environment.apiVersion}`;

let headers = new HttpHeaders();
    headers = headers.append('accept', 'application/json');
    headers = headers.append('Content-Type', 'application/x-www-form-urlencoded');

const headers_register = new HttpHeaders({
    'Content-Type': 'application/json',
});

@Injectable({
  providedIn: 'root',
})
export class AuthHTTPService {
  constructor(private http: HttpClient) {}

  // public methods
  login(username: string, password: string): Observable<any> {
    let body = new URLSearchParams();
    body.set('username', username);
    body.set('password', password);
    return this.http.post<AuthModel>(`${API_USERS_URL}/login/classic`, body, { headers: headers });
  }

  loginViaGoogle(): Observable<any> {
    return this.http.get<AuthModel>(`${API_USERS_URL}/login/google`, { headers: headers_register });
  }

  loginViaFacebook(email?: string): Observable<any> {

    if(email && email.length > 0) {
      let paramsList = new HttpParams;
          paramsList = paramsList.append('email', email);

      return this.http.get<AuthModel>(`${API_USERS_URL}/login/facebook`, { headers: headers_register, params: paramsList });
    } else {
      return this.http.get<AuthModel>(`${API_USERS_URL}/login/facebook`, { headers: headers_register });
    }
    
  }

  // CREATE =>  POST: add a new user to the server
  createUser(user: any): Observable<any> {
    const data = {
      email: user.email,
      password: user.password
    };
    return this.http.post<any>(`${API_USERS_URL}/register/user`, data, { headers: headers_register });
  }

  getResetPasswordCodeWithoutToken(email: string): Observable<any> {
    const params = new HttpParams().set('email', email);
    const data = {}
    return this.http.post<any>(`${API_USERS_URL}/users/reset-password-code`, data, { headers: headers, params: params });
  }

  sendGoogleLoginCode(code: string) {
    const params = new HttpParams().set('code', code);
    return this.http.get<AuthModel>(`${API_USERS_URL}/auth/google/token`, { headers: headers_register, params: params });
  }

  sendFacebookLoginCode(code: string, state?: string) {
    let params = new HttpParams().set('code', code);
    if(state) {
      params = params.append('state', state);
    } else {
      params = params.append('state', 'null');
    }
    return this.http.get<AuthModel>(`${API_USERS_URL}/auth/facebook/token`, { headers: headers_register, params: params });
  }

  getUserByToken(token: string): Observable<UserModel> {
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    return this.http.get<UserModel>(`${API_USERS_URL}/users/profile/me`, {
      headers: httpHeaders,
    });
  }

  getUserRolesByToken(token: string): Observable<UserModel> {
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    return this.http.get<UserModel>(`${API_USERS_URL}/me`, {
      headers: httpHeaders,
    });
  }

  refreshToken(user: any): Observable<any> {
    const data = {
      username: user.email,
      password: user.password
    };
    return this.http.post<any>(`${API_USERS_URL}/refresh-token`, data, { headers: headers_register });
  }

  getResetPasswordCode(token: string): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    return this.http.get<any>(`${API_USERS_URL}/users/reset-password-code`, { headers: httpHeaders });
  }

  changePasswordWithCode(token: string, changePasswordData: any, code: string) {
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });

    const data = {
      code: code,
      new_password: changePasswordData.new_password,
      refresh_token: 'aDPatyhLQ432v'
    }

    return this.http.post<any>(`${API_USERS_URL}/users/reset-password`, data, { headers: httpHeaders });
  }

  handleError(error: any) {
    console.error(error.message);
    return throwError(error);
  }

}
