import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard  {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authService.currentUserValue;
    if (currentUser) {
      // logged in so return true
      return true;
    }

    if(state.url.startsWith('/leads/public/details/')) {
      const url = state.url;
      const urlSegments = url.split('/');
      const idIndex = urlSegments.findIndex(segment => segment === 'details');
      
      if (idIndex !== -1 && idIndex + 1 < urlSegments.length) {
        this.router.navigate(['/search/details/', urlSegments[idIndex + 1]]);
      } else {
        localStorage.setItem('redirectUrlKontrakton', state.url); // Save current url before redirect
        this.logout();
      }
    } else {
      localStorage.setItem('redirectUrlKontrakton', state.url); // Save current url before redirect
      this.logout();
    }

  }

  logout() {
    this.authService.logout();
    return false;
  }
}
