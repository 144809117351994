import { ChangeDetectorRef, Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, of, Subscription, forkJoin } from 'rxjs';
import { map, catchError, switchMap, finalize } from 'rxjs/operators';
import { UserModel } from '../models/user.model';
import { AuthModel } from '../models/auth.model';
import { AuthHTTPService } from './auth-http';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';

import { jwtDecode } from "jwt-decode";
import { toNumber } from 'ng-zorro-antd/core/util';
import { NotificationsService } from 'src/app/services/notifications.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

export type UserType = UserModel | undefined;

const API_USERS_URL = `${environment.apiUrl}${environment.apiVersion}`;

let headers = new HttpHeaders();
    headers = headers.append('accept', 'application/json');
    headers = headers.append('Content-Type', 'application/x-www-form-urlencoded');

const headers_register = new HttpHeaders({
    'Content-Type': 'application/json',
});

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  // private fields
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;

  // public fields
  currentUser$: Observable<UserType>;
  isLoading$: Observable<boolean>;
  currentUserSubject: BehaviorSubject<UserType>;
  isLoadingSubject: BehaviorSubject<boolean>;
  currentRegisterError: any;
  currentUserRole: number;

  currentUserFullDetails: UserModel;
  userDataDownloaded: boolean = false;

  code: string;
  state: string;
  hasError: boolean;
  returnUrl: string;

  get currentUserValue(): UserType {
    return this.currentUserSubject.value;
  }

  set currentUserValue(user: UserType) {
    this.currentUserSubject.next(user);
  }

  constructor(
    private authHttpService: AuthHTTPService,
    private router: Router,
    private message: NzMessageService,
    private route: ActivatedRoute,
    private notificationService: NotificationsService,
    private http: HttpClient
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.currentUserSubject = new BehaviorSubject<UserType>(undefined);
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
  }

  decodeUserToken(token: string) {
    const decoded = jwtDecode(token);
    return decoded;
  }

  getUserRoleFromToken() {
    const curentToken = this.getAuthFromLocalStorage();
    if(curentToken) {
      const decodedToken = this.decodeUserToken(curentToken.access_token);
      if ('role_id' in decodedToken) {
        return decodedToken.role_id;
      } else {
        this.logout();
      }
    }
  }

  // public methods
  login(email: string, password: string): Observable<UserType> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.login(email, password).pipe(
      map((auth: AuthModel) => {
        const result = this.setAuthFromLocalStorage(auth);
        this.createMessage('success', 'Zalogowano pomyślnie'); // przeniesiony komunikat
        return result;
      }),
      switchMap(() => this.getUserByToken()),
      catchError((err) => {
        console.error('err', err);
        if(err.error.hint) {
          this.createMessage('error', err.error.hint);
        } else {
          this.createMessage('error', 'Wystąpił błąd podczas logowania.');
        }
        return of(undefined);
      }),
      finalize(() => {
        this.checkRedirectAfterLogin();
        this.isLoadingSubject.next(false);
      })
    );
  }

  loginViaGoogle() {
    return this.authHttpService.loginViaGoogle().subscribe((data: any) => {
      const googleUrl = data;
        if(googleUrl) {
          window.open(googleUrl, '_self'); // _self - in current frame, _blank - in new frame
        }
    });
  }

  loginViaGoogleNextStage() {
    this.route.queryParams.subscribe(params => {
      this.code = params['code'];
    });
    if(this.code) {
      this.isLoadingSubject.next(true);
      return this.authHttpService.sendGoogleLoginCode(this.code).pipe(
        map((auth: any) => {
          const result = this.setAuthFromLocalStorage(auth);
          this.createMessage('success', 'Zalogowano pomyślnie'); // przeniesiony komunikat
          return result;
        }),
        switchMap(() => this.getUserByToken()),
        catchError((err) => {
          console.error('err', err);
          if(err.error.hint) {
            this.createMessage('error', err.error.hint);
          } else {
            this.createMessage('error', 'Wystąpił błąd podczas logowania.');
          }
          return of(undefined);
        }),
        finalize(() => {
          this.isLoadingSubject.next(false);
        })
      )
      .subscribe((user: UserModel | undefined) => {
        if (user) {
          this.router.navigate(['/']);
          this.checkRedirectAfterLogin();
        } else {
          this.hasError = true;
        }
      });
    } else {
      return of(undefined);
    }
  }

  loginViaFacebook(email?: string) {
    if(email && email.length > 0) {
      return this.authHttpService.loginViaFacebook(email).subscribe((data: any) => {
        const facebookUrl = data;
          if(facebookUrl) {
            window.open(facebookUrl, '_self'); // _self - in current frame, _blank - in new frame
          }
      });
    } else {
      return this.authHttpService.loginViaFacebook().subscribe((data: any) => {
        const facebookUrl = data;
          if(facebookUrl) {
            window.open(facebookUrl, '_self'); // _self - in current frame, _blank - in new frame
          }
      });
    }
  }

  loginViaFacebookNextStage() {
    this.route.queryParams.subscribe(params => {
      this.code = params['code'];
      this.state = params['state'];
    });
    if(this.code) {
      this.isLoadingSubject.next(true);
      return this.authHttpService.sendFacebookLoginCode(this.code, this.state).pipe(
        map((auth: any) => {
          const result = this.setAuthFromLocalStorage(auth);
          this.createMessage('success', 'Zalogowano pomyślnie'); // przeniesiony komunikat
          return result;
        }),
        switchMap(() => this.getUserByToken()),
        catchError((err) => {
          console.error('err', err);
          if(err.status === 401) {
            this.router.navigate(['/auth/facebook/token'], { queryParams: { emailModal: true }});
          }
          if(err.error.hint) {
            this.createMessage('error', err.error.hint);
          } else {
            this.createMessage('error', 'Wystąpił błąd podczas logowania.');
          }
          return of(undefined);
        }),
        finalize(() => {
          this.isLoadingSubject.next(false);
        })
      )
      .subscribe((user: UserModel | undefined) => {
        if (user) {
          this.router.navigate(['/']);
          this.checkRedirectAfterLogin();
        } else {
          this.hasError = true;
        }
      });
    } else {
      return of(undefined);
    }
  }

  createMessage(type: string, message: string): void {
    this.message.create(type, message);
  }

  logout() {
    localStorage.removeItem(this.authLocalStorageToken);
    this.router.navigate(['/auth/login'], {
      queryParams: {},
    });
  }

  getUserByToken(): Observable<UserType> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.access_token) {
      return of(undefined);
    }
  
    this.isLoadingSubject.next(true);
  
    return forkJoin([
      this.authHttpService.getUserByToken(auth.access_token),
      this.authHttpService.getUserRolesByToken(auth.access_token)
    ]).pipe(
      switchMap(([user, userDetails]) => {
        if (user && userDetails) {
          const userModel = new UserModel();
          userModel.setUser(user);
          userModel.user_id = userDetails.user_id;
          userModel.broker = userDetails.broker;
          userModel.contractor = userDetails.contractor;
          userModel.trusted_broker = userDetails.trusted_broker;
          userModel.job_offerer = userDetails.job_offerer;
          userModel.logged_at = new Date(userDetails.logged_at);
          userModel.expires_at = new Date(userDetails.expires_at);
          userModel.form_of_cooperation_name = userDetails.form_of_cooperation_name;
          userModel.role_id = this.getUserRoleFromToken();
  
          this.currentUserSubject.next(userModel);

          return of(userModel);
        } else {
          this.logout();
          return of(undefined);
        }
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  getUserByRefreshToken(reAuth: AuthModel): Observable<UserType> {
    this.setAuthFromLocalStorage(reAuth);
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.access_token) {
      return of(undefined);
    }
    this.isLoadingSubject.next(true);
    return this.authHttpService.getUserByToken(reAuth.access_token).pipe(
      map((user: UserType) => {
        if (user) {
          this.currentUserSubject.next(user);
        } else {
          this.logout();
        }
        return user;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  // need create new user then login
  registration(user: UserModel): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.createUser(user).pipe(
      map(() => {
        this.isLoadingSubject.next(false);
      }),
      switchMap(() => this.login(user.email, user.password)),
      catchError((err) => {
        console.error('err', err);
        this.currentRegisterError = err.error.hint;
        return of(undefined);
      }),
      finalize(() => {
        this.checkRedirectAfterLogin();
        this.isLoadingSubject.next(false);
      })
    );
  }

  // private methods
  private setAuthFromLocalStorage(auth: AuthModel): boolean {
    // store auth authToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
    if (auth && auth.access_token) {
      localStorage.setItem(this.authLocalStorageToken, JSON.stringify(auth));
      return true;
    }
    return false;
  }

  private getAuthFromLocalStorage(): AuthModel | undefined {
    try {
      const lsValue = localStorage.getItem(this.authLocalStorageToken);
      if (!lsValue) {
        return undefined;
      }

      const authData = JSON.parse(lsValue);
      return authData;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  getToken() {
    try {
      const lsValue = localStorage.getItem(this.authLocalStorageToken);
      if (!lsValue) {
        return "";
      }

      const authData = JSON.parse(lsValue);
      return authData.access_token;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  refreshToken(data: any) {
    if(data) {
      const authData = {
        username: data.email,
        password: data.password
      }
      if(authData) {
        return this.authHttpService.refreshToken(authData).subscribe((data: any) => {

        });
      }
    }
  }

  getResetPasswordCode() {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.access_token) {
      return of(undefined);
    }
    this.authHttpService.getResetPasswordCode(auth.access_token).subscribe((data: any) => {
      this.notificationService.createNotification('success', '', data.hint, 7000);
    }, error => {
      this.notificationService.createNotification('error', 'Wystąpił błąd', error.error.hint, 7000);
    });
  }

  getResetPasswordCodeWithoutToken(email: string) {
    return this.authHttpService.getResetPasswordCodeWithoutToken(email).subscribe((data: any) => {
      this.notificationService.createNotification('success', '', data.hint, 7000);
    }, error => {
      this.notificationService.createNotification('error', 'Wystąpił błąd', error.error.hint, 7000);
    });
  }

  changePasswordWithoutToken(changePasswordData: any) {

    const data = {
      email: changePasswordData.email,
      code: changePasswordData.code,
      new_password: changePasswordData.new_password,
      refresh_token: 'aDPatyhLQ432v'
    }

    return this.http.post<any>(`${API_USERS_URL}/users/reset-password-not-logged`, data, { headers: headers_register });
  }

  changePasswordWithCode(changePasswordData: any, code: string) {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.access_token) {
      return of(undefined);
    }
    this.authHttpService.changePasswordWithCode(auth.access_token, changePasswordData, code).subscribe((data: any) => {
      this.notificationService.createNotification('success', 'Hasło zostało zmienione!', 'Zaloguj się ponownie do konta używając nowego hasła.', 7000);
      this.logout();
      document.location.reload();
    }, error => {
      this.notificationService.createNotification('error', 'Wystąpił błąd', error.error.hint, 7000);
    });
  }

  isAuthenticated() {
    if(this.getToken() && this.getToken() !== null && this.getToken() !== undefined && this.getToken().length > 0 && this.currentUserValue && this.currentUserValue !== undefined) {
      return true; 
    } else {
      return false;
    }
  }

  checkRedirectAfterLogin() {
    const userRedirect = localStorage.getItem('redirectUrlKontrakton');
    const redirectUrlKontraktonAfterLogout = localStorage.getItem('redirectUrlKontraktonAfterLogout');
    if(userRedirect && userRedirect !== undefined && userRedirect !== null) {
      if(!redirectUrlKontraktonAfterLogout || redirectUrlKontraktonAfterLogout == undefined || redirectUrlKontraktonAfterLogout == null) {
        this.router.navigate([userRedirect]);
        this.createMessage('success', 'Nastąpiło przekierowanie do poprzedniej strony');
        localStorage.removeItem('redirectUrlKontrakton');
        localStorage.removeItem('redirectUrlKontraktonAfterLogout');
      } else {
        localStorage.removeItem('redirectUrlKontrakton');
        localStorage.removeItem('redirectUrlKontraktonAfterLogout');
      }
    }
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }

}
