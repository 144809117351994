// USA
export const locale = {
  lang: 'pl',
  data: {
    TRANSLATOR: {
      SELECT: 'Wybierz swój język',
    },
    MENU: {
      NEW: 'nowy',
      ACTIONS: 'Działania',
      CREATE_POST: 'Utwórz nowy post',
      PAGES: 'Strony',
      FEATURES: 'Funkcje',
      APPS: 'Aplikacje',
      DASHBOARD: 'Panel nawigacyjny',
      LEADS: {
        MAIN: 'Leady',
        LIST: 'Publiczne',
        CHANGELOG: 'Dziennik aktywności',
        CREATE: 'Stwórz lead',
        MY_LEADS: 'Moje leady',
        MANAGE_LEADS: 'Zarządzanie leadami',
        LEAD_DETAILS: 'Szczegóły lead',
        DRAFTS: 'Wersje robocze',
        PURCHASED: 'Zakupione',
        SOLD: 'Sprzedane'
      },
      JOBS: {
        MAIN: 'Twoi zleceniodawcy',
        LIST: 'Lista zleceń',
        OFFERERS: 'Lista zleceniodawców',
        JOB_DETAILS: 'Szczegóły zlecenia'
      },
      REGISTER_ROLE: {
        CONTRACTOR: 'Zostań Zleceniobiorcą',
        BROKER: 'Zostań Brokerem',
      },
      COMPLAINTS: {
        MAIN: 'Reklamacje',
        LIST: 'Lista reklamacji',
      },
      ADMINISTRATOR: {
        USERS: 'Użytkownicy',
        MAIN: 'Panel Administratora',
        DASHBOARD: 'Dashboard',
        TRANSACTIONS: 'Wypłaty',
        TRANSACTIONS_DETAILS: 'Szczegóły wypłaty',
        USERS_DETAILS: 'Szczegóły użytkownika',
        JOB_OFFERERS: 'Zleceniodawcy',
        LEADS: 'Zarządzanie leadami'
      },
      TRANSACTIONS: {
        MAIN: 'Transakcje',
        HISTORY: 'Historia',
      },
      USERS: {
        MAIN: 'Użytkownicy',
      },
      ACCOUNT: {
        MAIN: 'Twoje konto',
        OVERVIEW: 'Podsumowanie',
        EDIT: 'Edytuj konto',
        MY_WALLET: 'Mój portfel',
        TRANSACTIONS_HISTORY: 'Historia transakcji',
      },
    },
    AUTH: {
      GENERAL: {
        OR: 'Albo',
        SUBMIT_BUTTON: 'Wyślij',
        NO_ACCOUNT: 'Nie masz konta?',
        SIGNUP_BUTTON: 'Zarejestruj się',
        FORGOT_BUTTON: 'Zapomniałeś hasła',
        BACK_BUTTON: 'Powrót',
        PRIVACY: 'Prywatność',
        LEGAL: 'Prawo',
        CONTACT: 'Kontakt',
      },
      LOGIN: {
        TITLE: 'Zaloguj się',
        BUTTON: 'Zaloguj się',
      },
      FORGOT: {
        TITLE: 'Zapomniałeś hasła?',
        DESC: 'Wprowadź swój adres e-mail, aby zresetować hasło',
        SUCCESS: 'Twoje konto zostało pomyślnie zresetowane.'
      },
      REGISTER: {
        TITLE: 'Zarejestruj się',
        DESC: 'Wprowadź swoje dane, aby utworzyć konto',
        SUCCESS: 'Twoje konto zostało pomyślnie zarejestrowane.'
      },
      INPUT: {
        EMAIL: 'E-mail',
        FULLNAME: 'Imię i nazwisko',
        PASSWORD: 'Hasło',
        CONFIRM_PASSWORD: 'Potwierdź hasło',
        USERNAME: 'Nazwa użytkownika'
      },
      VALIDATION: {
        INVALID: '{{name}} nie jest prawidłowe',
        REQUIRED: '{{name}} jest wymagane',
        MIN_LENGTH: 'Minimalna długość pola {{name}} to {{min}}',
        AGREEMENT_REQUIRED: 'Akceptacja regulaminu jest wymagana',
        NOT_FOUND: '{{name}} nie zostało znalezione',
        INVALID_LOGIN: 'Nieprawidłowe dane logowania',
        REQUIRED_FIELD: 'Pole wymagane',
        MIN_LENGTH_FIELD: 'Minimalna długość pola:',
        MAX_LENGTH_FIELD: 'Maksymalna długość pola:',
        INVALID_FIELD: 'Pole jest nieprawidłowe',
      }
    },
    ECOMMERCE: {
      COMMON: {
        SELECTED_RECORDS_COUNT: 'Liczba wybranych rekordów: ',
        ALL: 'Wszystko',
        SUSPENDED: 'Zawieszone',
        ACTIVE: 'Aktywne',
        FILTER: 'Filtr',
        BY_STATUS: 'według statusu',
        BY_TYPE: 'według typu',
        BUSINESS: 'Biznesowe',
        INDIVIDUAL: 'Osobiste',
        SEARCH: 'Szukaj',
        IN_ALL_FIELDS: 'we wszystkich polach'
      },
      ECOMMERCE: 'eCommerce',
      CUSTOMERS: {
        CUSTOMERS: 'Klienci',
        CUSTOMERS_LIST: 'Lista klientów',
        NEW_CUSTOMER: 'Nowy klient',
        DELETE_CUSTOMER_SIMPLE: {
          TITLE: 'Usuń klienta',
          DESCRIPTION: 'Czy na pewno chcesz trwale usunąć tego klienta?',
          WAIT_DESCRIPTION: 'Usuwanie klienta...',
          MESSAGE: 'Klient został usunięty'
        },
        DELETE_CUSTOMER_MULTY: {
          TITLE: 'Usuń klientów',
          DESCRIPTION: 'Czy na pewno chcesz trwale usunąć wybranych klientów?',
          WAIT_DESCRIPTION: 'Usuwanie klientów...',
          MESSAGE: 'Wybrani klienci zostali usunięci'
        },
        UPDATE_STATUS: {
          TITLE: 'Status został zaktualizowany dla wybranych klientów',
          MESSAGE: 'Status wybranych klientów został pomyślnie zaktualizowany'
        },
        EDIT: {
          UPDATE_MESSAGE: 'Klient został zaktualizowany',
          ADD_MESSAGE: 'Klient został utworzony'
        }
      }
    }
  }
};
